<template>
    <div>
        <page-header data-cy="allotment_index_title" :btnNew="isUserR2() ?'ACTIONS.NEW' : false" pageTitle="COMPANIES.INDEX_TITLE"
                     @newType="setRegisterModal"/>

        <list-component :items="items.data" :haveDetails="false" :isCompany="true" v-if="items && items.data">
            <template v-slot:principal-info="{items}">
                <div class="list-primary hide-text">{{ items.iten.name }}</div>
                <div class="list-secondary hide-text">{{ items.iten.taxpayer }}</div>
                <div class="list-secondary hide-text">{{ items.iten.id }}</div>
            </template>
            <template v-slot:dropdown-options="items">
                <div data-cy="company_dropdown_edit"
                     class="dropdown-item pointer text-white"
                     data-target="#customModalTwo"
                     data-toggle="modal"
                     @click="setEditModal(items.iten)"
                     v-if="canEdit(items.iten) && (hasUpdate[items.iten.id] === true)">
                    <i class="icon-mode_edit"></i> {{ t('ACTIONS.EDIT') }}
                </div>
            </template>
        </list-component>

        <modal :titleModal="modalTitle" :modalLarge="true">
            <template v-slot:title>{{ t(modalTitle) }}</template>
            <create-company v-if="company && address" :companyIndex="company" :addressIndex="address"
                            @created="updateAfterModified" @logo="cropper($event)">
            </create-company>
        </modal>
    </div>
</template>

<script>

import {useI18n} from "vue-i18n";
import jwt_decode from "jwt-decode";
import Modal from "@/components/modal";
import logo from "@/../public/logo.png";
import Companies from "@/services/Companies";
import {useToast} from "vue-toastification";
import Validate from '@/components/validate.vue';
import Permission from '@/components/permission.vue';
import CreateCompany from "./createCompanyComponent";
import pageHeader from "@/components/layouts/pageHeader";
import ListComponent from "@/components/layouts/listComponent.vue";

export default {
    name: 'ListCompany',
    mixins: [Permission, Validate],
    components: {
        Modal,
        pageHeader,
        CreateCompany,
        ListComponent,
    },

    setup() {
        const {t} = useI18n();
        const toast = useToast();

        return {t, toast}
    },

    data() {
        return {
            img: null,
            items: [],
            hasUpdate: [],
            permissions: null,
            rules: null,
            company: null,
            address: null,
            modalTitle: '',
            hasShowPermission: false,
            hasDeletePermission: false,
            hasUpdatePermission: false,
        }
    },

    mounted() {
        this.$store.commit('changeLoading', true);
        this.index();
        this.rules = jwt_decode(localStorage.getItem('access_token')).roles;
    },

    methods: {
        isUserR2() {
            const token = jwt_decode(localStorage.getItem('access_token'));
            return token.email.includes('@r2soft.com.br');
        },
        canEdit(item) {
            this.rules.companies.forEach(companies => {
                companies.companies.forEach(company => {
                    if (company === item.id) {
                        for (let index in this.rules) {
                            if (index !== 'companies' && this.hasUpdate[item.id] === undefined) {
                                if (this.rules[index]?.group_id === companies.group_id) {
                                    let permissions = this.rules[index].roles;
                                    permissions.forEach(per => {
                                        if (per == 'Company:update') {
                                            this.hasUpdate[item.id] = true;
                                        }
                                    });
                                }
                            }
                        }
                    }
                })
            });
            return true;
        },

        index() {
            this.$store.commit('changeLoading', true);
            Companies.index().then(resp => {
                this.items = resp;
                this.$store.commit('changeLoading', false);
                if (this.items.data.length !== 1) {
                    this.items.data.forEach(item => {
                        this.startPermissions(item, true);
                    })
                } else {
                    this.startPermissions(this.items.data[0], false);
                }
            }).catch(error => {
                this.errorMsg(error)
                this.$store.commit('changeLoading', false);
            });
        },

        open(client) {
            client.open = !client.open ? true : false;
        },

        startPermissions(company, companyUnique) {
            if (company) {
                let idCompany = localStorage.getItem('companyId');
                this.rules.companies.forEach(companies => {
                    companies.companies.forEach(company => {
                        if (company === idCompany) {
                            for (let index in this.rules) {
                                if (index !== 'companies' && this.rules[index]?.group_id === companies.group_id) {
                                    this.permissions = this.rules[index].roles;
                                }
                            }
                        }
                    })
                });

                if (this.permissions) {
                    this.permissions.forEach(per => {
                        if (per == 'Company:update') {
                            company.hasUpdatePermission = true;
                        }
                        if (per == 'Company:show') {
                            company.hasShowPermission = true;
                        }
                        if (per == 'Company:delete') {
                            company.hasDeletePermission = companyUnique;
                        }
                    });
                }
            }
        },

        setRegisterModal() {
            this.modalTitle = 'COMPANIES.MODAL_TITLE.ADD';

            this.company = {
                id: null,
                name: null,
                email: null,
                taxpayer: null,
                is_required_data_client: false,
                email_signature: '',
                logo: logo,
            }

            this.address = {
                cep: null,
                street: null,
                number: null,
                neighborhood: null,
                complement: null,
                city_id: null,
            }
        },

        setEditModal(iten) {
            this.$store.commit('changeLoading', true);
            Companies.show(iten.id).then(resp => {
                this.company = JSON.parse(JSON.stringify(resp.data));

                if (resp.data.address) {
                    this.address = resp.data.address;
                } else {
                    this.address = {
                        cep: null,
                        street: null,
                        number: null,
                        neighborhood: null,
                        complement: null,
                        city_id: null,
                    }
                }

                this.$store.commit('changeLoading', false);
            }).catch(error => {
                this.errorMsg(error)
                this.$store.commit('changeLoading', false);
            });

            this.modalTitle = 'COMPANIES.MODAL_TITLE.EDIT';
        },

        updateAfterModified() {
            this.index();
            this.$store.commit('startCompaniesToken');
            this.rules = jwt_decode(localStorage.getItem('access_token')).roles;
        },

        async updateCompany(id, company) {
            await this.$store.dispatch('ActionRefreshToken');
            this.updateAfterModified();

            this.toast.success(company.validations.message);

            if (localStorage.getItem('companyId') == id) {
                localStorage.removeItem('companyId');

                if (this.items.data.validations) {
                    this.$router.go();
                } else
                    this.$store.commit('showModalSelectCompany', true);
            }
        },

        cropper(image) {
            this.$store.commit('setImage', image);
            document.getElementById('imageCropper').click();
        }
    }
}
</script>
